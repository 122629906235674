import { template as template_e0a0e8403efc42ee8086c15dc8a75ee9 } from "@ember/template-compiler";
const SidebarSectionMessage = template_e0a0e8403efc42ee8086c15dc8a75ee9(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
